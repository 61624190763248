/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* tailwindcss CSS */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Montserrat"; //This is what we are going to call
  src: url("assets/fonts/Montserrat-VariableFont_wght.ttf");
}

* {
  font-family: Montserrat !important;
}

ion-title {
  color: var(--ion-color-letras);
  font-weight: 550;
  font-size: 25px;
}

ion-toolbar {
  --mode: "ios";
  --border-style: none;
  --background: #f5f6f8 !important;
}

ion-header {
  --mode: "ios";
  --border-style: none;
}

ion-content {
  --ion-item-background: transparent;
  --background: #f5f6f8;
}

ion-card-content {
  --background: #fff;
  padding: 10px !important;
  ion-item {
    --background: #fff;
    --padding-start: 0;
    --inner-padding-end: 0;
    ion-label {
      --color: #1e1e1e !important;
      span {
        color: #5c6166;
      }
    }
  }
}

ion-item {
  --background: #f5f6f8;
  --border-width: 0;
  --border-style: none;
  ion-label {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

//Regla para que los ion-item no tengan margen entre elementos
.item-recuadro-informacion {
  --min-height: 0px;
  font-weight: bold;
  span {
    font-weight: 500;
  }
}

ion-card {
  --background: #fff;
}

ion-menu-button {
  color: var(--ion-title-color);
}

ion-item:last-child {
  --border-style: none;
}

ion-menu {
  ion-list:last-child {
    div {
      ion-item {
        --border-style: none;
      }
    }

    div:last-child {
      ion-list {
        border-bottom: none !important;
      }
    }
  }
}

.title-tab {
  color: var(--ion-color-letras);
  font-weight: 500 !important;
  font-size: 20px;
}

.description-tab {
  color: var(--ion-color-letras-contrast);
  font-weight: 450 !important;
  font-size: 15px;
}

.button-selected {
  padding-left: 7px;
  padding-right: 7px;
  background-color: var(--ion-color-primary-tint);
  border-radius: 30px;
  padding-top: 7px;
  padding-bottom: 7px;
}

[aria-checked="true"] .alert-checkbox-icon {
  background: #f96332 !important;
  border-color: #f96332 !important;
}

.label-description {
  --color: #5c6166 !important;
}

.header-fab-button {
  --background: #f5f6f8;
  --box-shadow: var(--background);
  margin-top: 30%;
}

.action-sheet-options {
  --button-color: #5c6166;
}

.disavit-alert {
  .alert-message {
    color: #5c6166;
  }
}

.select-measurement {
  margin-left: 10px;
  min-width: 95% !important;
  border-radius: 25px;
  padding: 5px 10px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  background: #ffffff;
}

.span-date {
  font-family: Montserrat;
  color: #aeaeae;
  font-size: 16px;
  font-weight: 400;
}

//MODAL con calendario
.modal-datetime {
  z-index: 4000;
  --height: 50%;
  --border-radius: 6px;
  --width: 90%;

  &::part(content) {
    backdrop-filter: blur(6px);
  }

  ion-content {
    --background: transparent;
    --padding-top: 5vh;
    --padding-start: 20px;
    --padding-end: 20px;

    ion-datetime {
      border-radius: 8px;

      ion-button {
        font-family: Montserrat;
      }
      ion-button:first-child {
        padding-right: 30vw;
      }
    }
  }
}
